<template>
  <div v-if="getOrderDetail" class="order-detail-wrap">
    <div class="back-to-list-text cus-is-button" @click="$router.go(-1)">
      <el-icon style="margin-right: 18px"><Back /></el-icon>
      返回列表
    </div>
    <el-tabs v-model="activeName" style="margin-top: 30px">
      <!-- 訂單內容 -->
      <el-tab-pane label="訂單內容" name="cart">
        <div class="cart-wrap">
          <el-table
            :data="getOrderDetail?.order_items"
            class="cart-table"
            empty-text="尚無資料"
            header-cell-class-name="cart-table-header"
            :row-class-name="tableRowClassName"
          >
            <el-table-column label="品名" width="200">
              <template #default="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="券別" width="120">
              <template #default="scope">
                <span>{{ mapValue[scope.row.ticket_type]?.label }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sub_total" label="單價" width="100" />
            <el-table-column prop="quantity" label="數量" width="100" />
            <el-table-column prop="total" label="總價" width="100" />
            <el-table-column prop="option" label="編輯" :show-overflow-tooltip="true" />
            <el-table-column prop="ticket_code" label="電子券ID" width="130" />
            <el-table-column prop="from_source_code" label="電子券序號" width="200" />
          </el-table>
        </div>
      </el-tab-pane>
      <!-- 訂單資訊 -->
      <el-tab-pane label="訂單資訊" name="transaction">
        <div class="table-wrap">
          <div
            v-for="(obj, o_index) in Object.keys(transaction_list)"
            :key="o_index"
            class="box-bg"
            :style="`width:${transaction_list[obj].width || 100}%`"
          >
            <div class="title">
              {{ transaction_list[obj].title }}
              <span
                v-if="transaction_list[obj].title === '訂單資訊'"
                class="download-text"
                @click="handleClicEncodedNumber()"
              >
                查看交易明細序號
              </span>
            </div>
            <!-- 非活動資訊的區塊 -->
            <div v-if="obj !== 'event'" :class="['item-wrap', transaction_list[obj].class]">
              <div v-for="(item, i_index) in transaction_list[obj].items" :key="i_index" :class="['item', item.color]">
                <span>{{ item.label }}</span>
                <!-- 訂單時間/作廢時間/發票異動時間 -->
                <span
                  v-if="
                    item.value === 'create_datetime' ||
                    item.value === 'invalid_datetime' ||
                    item.value == 'invoice_last_action_time'
                  "
                >
                  {{
                    getOrderDetail[item.value].length >= 12
                      ? formatDateTime('YYYY/MM/DD HH:mm', getOrderDetail[item.value])
                      : '無'
                  }}
                </span>
                <!-- 來源 -->
                <span v-else-if="item.label === '來源'">
                  {{ getOrderDetail[item.value].split('-')[0] }}
                </span>
                <!-- 類型 -->
                <span v-else-if="item.label === '類型'">
                  {{ getOrderDetail[item.value].split('-')[1] }}
                </span>
                <!-- 發票狀態 -->
                <span v-else-if="item.value === 'invoice_status'">
                  {{ mapInvoiceStatus[getOrderDetail[item.value]].label }}
                </span>
                <!-- 載具 -->
                <span v-else-if="item.value === 'carrier_number'">
                  {{
                    getOrderDetail[item.value]
                      ? `${getOrderDetail.carrier_type === '自然人憑證' ? '(自)' : getOrderDetail.carrier_type}${
                          getOrderDetail.carrier_number
                        }`
                      : '無'
                  }}
                </span>
                <!-- 是否使用外送行動支付 -->
                <span v-else-if="item.value === 'payment_by_outside_payment_app'">
                  {{ getOrderDetail[item.value] ? '是' : '否' }}
                </span>
                <!--  支付資訊 -->
                <span v-else-if="obj === 'payment'">
                  {{ getOrderDetail[item.value] ? getOrderDetail[item.value] : 0 }}
                </span>
                <span v-else>
                  {{
                    getOrderDetail[item.value] || getOrderDetail[item.value] === 0 ? getOrderDetail[item.value] : '無'
                  }}
                </span>
              </div>
            </div>
            <!-- 活動資訊 -->
            <div v-else :class="[transaction_list[obj].class]">
              <div v-if="getOrderDetail.event_array.length > 0">
                <div
                  v-for="(event, e_index) in getOrderDetail.event_array"
                  :key="e_index"
                  class="item-wrap"
                  style="padding: 0"
                >
                  <div
                    v-for="(item, i_index) in transaction_list[obj].items"
                    :key="i_index"
                    :class="['item', item.color]"
                  >
                    <span>{{ item.label }}</span>
                    <span>
                      {{ getOrderDetail.event_array[e_index][item.value] || '無' }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-else>無</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 訂購人資訊 -->
      <el-tab-pane label="訂購人資訊" name="information">
        <el-descriptions :column="2" border style="margin-top: 40px">
          <el-descriptions-item
            v-for="item in information_list"
            :key="item.value"
            label-class-name="detail-label"
            :label="item.label"
          >
            <!-- 取餐時間 -->
            <span v-if="item.value === 'order_take_time'">
              {{ getOrderDetail[item.value] ? formatDateTime('YYYY/MM/DD HH:mm', getOrderDetail[item.value]) : '無' }}
            </span>
            <!-- 消費者 -->
            <span v-else-if="item.value === 'consumer'">
              {{ getConsumer(getOrderDetail.order_user_id, getOrderDetail.want_card_number) }}
            </span>
            <span v-else>
              {{ getOrderDetail[item.value] || '無' }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, watch, reactive, getCurrentInstance } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
import { mapValue, mapInvoiceStatus, getConsumer } from '@/utils/define';
import useClipboard from 'vue-clipboard3';
export default {
  name: 'order-detail',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const activeName = ref('cart');
    const screenHeight = document.documentElement.clientHeight;
    const contentHeight = ref(screenHeight - 470 + 'px');
    const transaction_list = reactive({
      order: {
        title: '訂單資訊',
        items: [
          { label: '交易序號', value: 'serial_number' },
          { label: '外部訂單編號', value: 'outside_order_id' },
          { label: '店號', value: 'store_erp_id' },
          { label: '門市名稱', value: 'shop_name' },
          { label: '訂單時間', value: 'create_datetime' },
          { label: '來源', value: 'source_platform' },
          { label: '類型', value: 'source_platform' },
          { label: '訂單狀態', value: 'status_description' },
          { label: '飲品−數量', value: 'total_item_count' },
          { label: '產品−數量', value: 'total_product_count' },
          { label: '周邊商品−數量', value: 'total_other_product_count' },
          { label: '作廢時間', value: 'invalid_datetime' },
        ],
      },
      checkout: {
        title: '結帳資訊',
        items: [
          { label: '訂單原金額', value: 'sub_total' },
          { label: '折扣金額', value: 'discount' },
          { label: '抵用金額', value: 'deduction' },
          { label: '訂單金額', value: 'total' },
          { label: '黃金點數贈點', value: 'extra_reward_point' },
          { label: '發票模式', value: 'invoice_type' },
          { label: '發票號碼', value: 'invoice_no' },
          { label: '發票金額', value: 'invoice_total_amount' },
          { label: '應稅金額', value: 'invoice_sales_amount' },
          { label: '免稅金額', value: 'invoice_free_tax_amount' },
          { label: '稅額', value: 'invoice_tax_amount' },
          { label: '發票狀態', value: 'invoice_status' },
          { label: '發票異動時間', value: 'invoice_last_action_time' },
          { label: '統一編號', value: 'ban' },
          { label: '載具', value: 'carrier_number' },
          { label: '捐贈碼', value: 'npo_ban' },
          { label: '結帳人員', value: 'checkout_user' },
          { label: '作廢人員', value: 'invalid_user' },
        ],
      },
      event: {
        title: '活動資訊',
        class: 'event-item-wrap',
        items: [
          { label: '活動類型', value: 'event_type' },
          { label: '活動ID', value: 'event_id' },
          { label: '活動名稱', value: 'event_name' },
          { label: '序號', value: 'event_source_code' },
        ],
      },
      payment: {
        title: '支付資訊',
        items: [
          { label: '使用外送行動支付', value: 'payment_by_outside_payment_app' },
          { label: '現金', value: 'cash' },
          { label: '旺卡儲值金', value: 'want_card' },
          { label: '黃金點數', value: 'want_card_point' },
          { label: '現金抵用卡', value: 'cash_card' },
          { label: '外部平台支付', value: 'delivery_collection' },
          { label: 'LINE PAY', value: 'line_pay' },
          { label: '街口支付', value: 'jeko' },
          { label: '悠遊付', value: 'uupay' },
          { label: '台灣PAY', value: 'taiwan_pay' },
          { label: '聯合−信用卡', value: 'credit_card' },
          { label: '聯合−一卡通', value: 'i_pass' },
          { label: '聯合−悠遊卡', value: 'easy_card' },
          { label: '支付寶', value: 'ali_pay' },
          { label: '禮券', value: 'gift_certificate' },
          { label: '園區員工卡', value: 'park_employee_card' },
        ],
      },
    });
    const information_list = reactive([
      { label: '訂購人', value: 'order_user_name' },
      { label: '消費者', value: 'consumer' },
      { label: '訂購電話', value: 'order_user_tel' },
      { label: '會員編號', value: 'order_user_id' },
      { label: '取餐時間', value: 'order_take_time' },
      { label: '旺卡卡號', value: 'want_card_number' },
      { label: '地址', value: 'order_user_address' },
      { label: '旺卡等級', value: 'level_description' },
      { label: '備註', value: 'note' },
      { label: '旺卡類別', value: 'want_card_type' },
    ]);
    //取得內容
    const { getOrderDetail, getEncodedNumber } = useGetters('order', ['getOrderDetail', 'getEncodedNumber']);
    const { CLEAR_ORDER_DETAIL, CLEAR_ENCODED_NUMBER } = useMutations('order', [
      'CLEAR_ORDER_DETAIL',
      'CLEAR_ENCODED_NUMBER',
    ]);
    if (!getOrderDetail.value) router.push('/order/list?page=1');
    watch(route, () => {
      if (!route.fullPath.includes('/detail')) {
        CLEAR_ORDER_DETAIL();
        CLEAR_ENCODED_NUMBER();
      }
    });
    //訂單內容折扣變色
    const tableRowClassName = ({ row }) => {
      if (row.item_type !== 0) return 'discount-item';
    };
    //取得交易明細序號
    const doGetEncodedNumber = useActions('order', ['doGetEncodedNumber']);
    const handleClicEncodedNumber = async () => {
      await doGetEncodedNumber(route.query);

      if (getEncodedNumber.value) {
        proxy
          .$confirm(`${getEncodedNumber.value}`, '交易明細序號', {
            confirmButtonText: '複製',
            cancelButtonText: '關閉',
            confirmButtonClass: 'el-button--success',
          })
          .then(() => {
            copy(getEncodedNumber.value);
          })
          .catch((e) => e);
      }
      const { toClipboard } = useClipboard();
      const copy = async (msg) => {
        try {
          // 複製
          await toClipboard(msg);
          proxy.$message({
            type: 'success',
            message: '複製成功',
          });
          // 複製成功
        } catch (e) {
          // 複製失败
        }
      };
    };
    return {
      mapValue,
      mapInvoiceStatus,
      formatDateTime,
      activeName,
      contentHeight,
      transaction_list,
      information_list,
      getOrderDetail,
      getEncodedNumber,
      getConsumer,
      tableRowClassName,
      handleClicEncodedNumber,
    };
  },
};
</script>
<style lang="scss" scoped>
.order-detail-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.cart-wrap {
  width: 100%;
  margin-top: 30px;
  height: v-bind(contentHeight);
  overflow: scroll;
  .cart-table {
    border: 1px solid $el-border-color-lighter;
    border-bottom: none;
  }
  :deep(.discount-item) {
    td:nth-of-type(1),
    td:nth-of-type(2),
    td:nth-of-type(3),
    td:nth-of-type(4),
    td:nth-of-type(5) {
      color: $color_primary;
    }
  }
}
:deep(.cart-table-header) {
  font-weight: 500;
  color: $color-text-light;
  background-color: $color-background !important;
}
:deep(.detail-label) {
  width: 150px;
  font-weight: 500 !important;
  color: $color-text-light;
  background-color: $color-background !important;
}
.table-wrap {
  width: 100%;
  border: 1px solid $el-border-color-lighter;
  margin-top: 30px;
  height: v-bind(contentHeight);
  overflow: scroll;
  @include flex-wrap;
  .box-bg {
    .title {
      width: 100%;
      height: 45px;
      font-weight: 500;
      color: $color-text-light;
      background-color: $color-background;
      line-height: 45px;
      padding: 0 20px;
    }
    .item-wrap {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 25%));
      grid-column-gap: 50px;
      padding: 8px 20px;
      .item {
        padding: 8px 0;
        @include flex-between-center;
      }
    }
    .event-item-wrap {
      padding: 8px 20px;
    }
  }
  .download-text {
    float: right;
  }
}
</style>
